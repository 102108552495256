import Vue from 'vue';
import Router from 'vue-router';
import { connectSocket, disconnectSocket } from '@/utils/socketConnection';
import store from '@/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: '/',
    //   name: 'home',
    //   component: () => import('@/views/main'),
    //   meta: {
    //     title: 'GolStats',
    //   },
    // },
    {
      path: '/gana',
      name: 'gana',
      component: () => import('@/views/main'),
      meta: {
        title: 'GolStats',
      },
    },
    {
      path: '/teamprofile',
      name: 'teamprofile',
      component: () => import('@/views/TeamProfile'),
      meta: {
        needSocket: true,
        title: 'Team Profile',
        stayOnLogout: true,
      },
    },
    {
      path: '/main',
      name: 'main',
      component: () => import('@/views/LandingMain'),
      meta: {
        needSocket: true,
        title: 'GolTipster',
        stayOnLogout: true,
      },
    },
    {
      path: '/apuesta',
      name: 'bet',
      component: () => import('@/views/Bets'),
      meta: {
        title: 'Apuesta',
        stayOnLogout: true,
      },
    },
    {
      path: '/widget/:team',
      name: 'widget',
      component: () => import('@/views/landing'),
      meta: {
        title: 'widget',
        stayOnLogout: true,
      },
    },
    {
      path: '/playerprofile',
      name: 'playerprofile',
      component: () => import('@/views/PlayerProfile'),
      meta: {
        needSocket: true,
        title: 'Player Profile',
        stayOnLogout: true,
      },
    },
    {
      path: '/set-bet-amount',
      name: 'set-bet-amount',
      component: () => import('@/views/SetBetAmount'),
      meta: {
        title: 'Monto',
      },
    },
    {
      path: '/set-bet-user',
      name: 'set-bet-user',
      component: () => import('@/views/SetBetUser'),
      meta: {
        title: 'Usuario',
      },
    },
    {
      path: '/choose-the-risk',
      name: 'choose-the-risk',
      component: () => import('@/views/ChooseRisk'),
      meta: {
        title: 'Elige el riesgo',
      },
    },
    {
      path: '/tips',
      name: 'tips',
      component: () => import('@/views/Tips'),
      meta: {
        title: 'Tips de GolStats',
      },
    },
    // {
    //   path: '/landing',
    //   name: 'landing',
    //   component: () => import('@/views/LandingMundial'),
    //   meta: {
    //     title: 'Mundial',
    //     isMenuHidden: true,
    //   },
    // },
    {
      path: '/',
      // name: 'landing',
      component: () => import('@/views/LandingMain'),
      meta: {
        title: 'GolTipster',
        isMenuHidden: true,
      },
      children: [
        {
          path: 'view-all',
          name: 'viewAll',
          component: () => import('@/views/ViewAll.vue'),
        },
        {
          path: 'extended-calendar',
          name: 'extendedCalendar',
          component: () => import('@/views/ExtendedCalendar.vue'),
          meta: {
            title: 'Calendario Extendido',
            isMenuHidden: true,
          },
        },
        {
          path: 'table',
          name: 'table',
          component: () => import('@/views/TableFull.vue'),
        },
        {
          path: 'golstats-data',
          name: 'golStatsData',
          component: () => import('@/views/GolStatsDataFull.vue'),
        },
        {
          path: 'tops',
          name: 'topsSection',
          component: () => import('@/views/TopsSection'),
          meta: {
            title: 'Tops',
            // isMenuHidden: true,
          },
        },
        {
          path: 'teams',
          name: 'teams',
          component: () => import('@/views/Teams.vue'),
          meta: {
            to: 'team',
          },
        },
        {
          path: 'player-teams',
          name: 'playerTeams',
          component: () => import('@/views/Teams.vue'),
          meta: {
            to: 'players',
          },
        },
        {
          path: 'team-players/:teamId',
          name: 'players',
          component: () => import('@/views/Players.vue'),
        },
        {
          path: 'player-profile',
          name: 'playerProfile',
          component: () => import('@/views/PlayerProfile'),
        },
        {
          path: 'team-profile',
          name: 'teamProfile',
          component: () => import('@/views/TeamProfile'),
        },
        {
          path: 'matches/:gameId',
          name: 'matchAnalysis',
          component: () => import('@/views/MatchAnalysis'),
          meta: {
            needSocket: true,
            title: 'Partido',
            stayOnLogout: true,
          },
        },
      ],
    },
    {
      path: '/bettoolmenu',
      name: 'bettoolmenu',
      component: () => import('@/views/BetToolMenu'),
      meta: {
        title: 'Bet Tool Menu',
      },
    },
    {
      path: '/password',
      name: 'Change Password',
      component: () => import('@/views/Change'),
      meta: {
        title: 'Change of Password',
      },
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import('@/views/Profile'),
      children: [
        {
          path: 'settings',
          name: 'settings',
          component: () => import('@/views/Profile/Settings/index'),
          children: [
            {
              path: 'payment-methods',
              name: 'payment-methods',
              component: () => import('@/views/Profile/Settings/PaymentMethods'),
            },
            {
              path: 'available-plans',
              name: 'available-plans',
              component: () => import('@/views/Profile/Settings/AvailablePlans'),
            },
          ],
        },
      ],
      meta: {
        title: 'Perfil',
      },
    },
    {
      path: '/profile-3pv',
      name: 'profile-3pv',
      component: () => import('@/views/Profile-3pv'),
      children: [
        {
          path: 'settings',
          name: 'settings',
          component: () => import('@/views/Profile-3pv/Settings/index'),
          children: [
            {
              path: 'payment',
              name: 'payment',
              component: () => import('@/views/Profile-3pv/Settings/PaymentMethods'),
            },
            {
              path: 'plans',
              name: 'plans',
              component: () => import('@/views/Profile-3pv/Settings/AvailablePlans'),
            },
          ],
        },
      ],
      meta: {
        title: 'Perfil',
      },
    },
    {
      path: '/matches',
      name: 'matches',
      component: () => import('@/views/Matches'),
      meta: {
        needSocket: true,
        title: 'Partidos',
        stayOnLogout: true,
      },
    },
    {
      path: '/matches/:gameId',
      name: 'match-analysis',
      component: () => import('@/views/MatchAnalysis'),
      meta: {
        needSocket: true,
        title: 'Partido',
        stayOnLogout: true,
      },
    },
    {
      path: '/lineups/:gameId',
      name: 'lineups',
      component: () => import('@/views/Lineups'),
      meta: {
        needSocket: true,
        title: 'Lineups',
        stayOnLogout: true,
      },
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('@/views/Terms'),
      meta: {
        title: 'Términos y condiciones',
      },
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('@/views/Contact'),
      meta: {
        title: 'Contacto',
      },
    },
    {
      path: '/contact-main',
      name: 'contact-main',
      component: () => import('@/views/ContactMain'),
      meta: {
        title: 'Contacto',
      },
    },
    {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/Calendar'),
      meta: {
        title: 'Calendario',
      },
    },
    {
      path: '/extendedcalendar',
      name: 'extendedcalendar',
      component: () => import('@/views/ExtendedCalendar.vue'),
      meta: {
        title: 'Calendario Extendido',
        isMenuHidden: true,
      },
    },
    {
      path: '/calendar/cup',
      name: 'calendarCup',
      component: () => import('@/views/CalendarCup'),
      meta: {
        title: 'Calendario',
        isMenuHidden: true,
      },
    },
    {
      path: '/calendargen',
      name: '/calendargen',
      component: () => import('@/views/Calendar/IframeLingGen'),
      meta: {
        title: 'Generador de Calendarios para Iframe',
      },
    },
    {
      path: '/table',
      name: 'table',
      component: () => import('@/views/Table'),
      meta: {
        title: 'Tabla',
      },
    },
    {
      path: '/tops',
      name: 'tops',
      component: () => import('@/views/Tops'),
      meta: {
        title: 'Tops',
      },
    },
    {
      path: '/mediaplayer',
      name: 'mediaplayer',
      component: () => import('@/views/MediaPlayerV2'),
      meta: {
        title: 'MediaPlayer',
      },
    },
    {
      path: '/playseeker',
      name: 'playSeeker',
      component: () => import('@/views/MediaPlayerV2'),
      meta: {
        title: 'Play Seeker',
      },
    },
    {
      path: '/mediaplayer2',
      name: 'mediaplayer2',
      component: () => import('@/views/MediaPlayer'),
      meta: {
        title: 'MediaPlayer',
      },
    },
    {
      path: '/login3pv',
      name: 'login3pv',
      component: () => import('@/views/login3pv'),
      meta: {
        title: '3pv',
      },
    },
    {
      path: '/main3pv',
      name: 'main3pv',
      component: () => import('@/views/main3pv'),
      meta: {
        title: '3pv',
      },
    },
    {
      path: '/calendar-gs',
      name: 'calendarGS',
      component: () => import('@/views/CalendarGS'),
      meta: {
        title: 'Calendar',
      },
    },
    {
      path: '/calendar-gs-2',
      name: 'calendarGS2',
      component: () => import('@/views/CalendarGS2'),
      meta: {
        title: 'Calendar',
      },
    },
    {
      path: '/goltipster',
      name: 'goltipster',
      component: () => import('@/views/GolTipster'),
      meta: {
        title: 'GolTipster',
      },
    },
    {
      path: '/goltipster-v2',
      name: 'goltipster-v2',
      component: () => import('@/views/GolTipster-v2'),
      meta: {
        title: 'GolTipster',
      },
    },
    {
      path: '/soyreferee',
      name: 'soyreferee',
      component: () => import('@/views/SoyReferee'),
      meta: {
        title: 'SoyReferee',
      },
    },
    {
      path: '/playseeker2',
      name: 'playSeeker',
      component: () => import('@/views/PlaySeeker'),
      meta: {
        title: 'Play Seeker',
      },
    },
    {
      path: '/tops-full',
      name: 'topsFull',
      component: () => import('@/views/TopsFull'),
      meta: {
        title: 'Tops',
        isMenuHidden: true,
      },
    },
    {
      path: '/gs-logout',
      name: 'gsLogout',
      component: () => import('@/views/GSLogout'),
      meta: {
        title: 'GolStats',
        isMenuHidden: true,
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  // Chage Page Title (if meta title is present)
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find(r => r.meta && r.meta.title);
  document.title = nearestWithTitle ? nearestWithTitle.meta.title : 'GolStats';
  store.commit('auth/SET_AUTH_COMPONENT', null);
  if (store.state.loginGeneral.user.premiumAccount && to.meta.needSocket) {
    connectSocket();
  } else {
    disconnectSocket();
  }
  next();
});

export default router;
